<template>
  <div class="account-number">
    <el-select class="accounts-select" v-model="accountID" size="small" data-testid="accountNumber">
      <el-option
        v-for="item in rebatesList"
        :key="item.login"
        :value="item.login"
        :label="setLabel(item)"
        :data-testid="item.login"
      >
      </el-option>
    </el-select>
    <p>* {{ $t('home.select_ib') }}</p>
    <img src="@/assets/images/dialog_success.png" class="success" alt="" />
  </div>
</template>

<script>
import { apiQueryRebatesList } from '@/resource';
import { getCookies, setCookies } from '@/util/cookies'

export default {
  data() {
    return {
      accountID: null,
      rebatesList: []
    };
  },
  watch: {
    accountID(accountID) {
      this.updateAccount(accountID);
    }
  },
  mounted() {
    this.queryRebatesList();
  },
  methods: {
    updateAccount(accountID = null) {
      const racc = getCookies('racc');
      if (racc) setCookies('racc', '');
      const foundAccount = this.rebatesList.find(f => f.login === accountID);
      this.$store.commit('behavior/setAID', foundAccount ? accountID : '');
      this.$store.commit('behavior/setCUR', foundAccount ? foundAccount.currency : '');
      this.$store.commit('behavior/setBalance', foundAccount ? foundAccount.commission : 0);
      this.$store.commit('behavior/setDataSourceId', foundAccount ? foundAccount.dataSourceId : null);
      this.$emit('accountCallback');
    },
    queryRebatesList() {
      return apiQueryRebatesList().then(resp => {
        if (resp.data.code === 0) {
          this.rebatesList = resp.data.data.list;
          const racc = getCookies('racc');
          if (racc && this.rebatesList.length > 0 && this.rebatesList.find(f => f.login === +racc)) {
            this.accountID = +racc;
          } else if (this.$store.state.behavior.AID) {
            this.accountID = this.$store.state.behavior.AID;
          } else {
            this.accountID = this.rebatesList[0] ? this.rebatesList[0].login : null;
          }

          if (!this.accountID) this.updateAccount();
        }
      }, this.$handleError);
    },
    setLabel(item) {
      return item.login + ' - ' + this.$config.accountTypeMaps[item.mtAccountType];
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/form/accountNumber.scss';
</style>
